<script setup>
import {ref} from "vue"

const form = ref(null)

const actionTitle = ref(null)
const action = ref(null)
const value = ref(null)
const loading = ref(false)

const showModal = ref(false)
const resourceName = ref(null)
const fieldValue = ref(null)

const onBeforeClose = (formAction, done) => {
  if (formAction === 'confirm') {
    form.value.validate().then(validated => {
      if (!validated) {
        return
      }

      action.value(done)
    })
  } else {
    done()
  }
}

defineExpose({
  showModal,
  resourceName,
  actionTitle,
  action,
  value,
  fieldValue,
  loading
})

</script>

<template>
  <var-style-provider :style-vars="{ '--dialog-width': '420px'}">
    <var-dialog
        v-model:show="showModal"
        confirm-button-text="Confirm"
        cancel-button-text="Cancel"
        @before-close="onBeforeClose">
      <template #title>
        {{ actionTitle }}
      </template>

      <p>Are you sure you want to delete {{ resourceName }}? Write <span class="italic">{{ value }}</span> and press Confirm.</p>
      <var-form ref="form" :disabled="loading">
        <var-input
            :placeholder="`${value}`"
            :rules="[
              v => !!v || 'This field cannot be empty',
              v => v === value || 'The field\'s value does not match the original'
            ]"
            v-model="fieldValue"
            class="mb-5"/>
      </var-form>
    </var-dialog>
  </var-style-provider>
</template>

<style scoped>

.italic {
  font-style: italic;
  font-weight: bolder;
}

</style>