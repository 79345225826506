<template>
	<page-header name="Projects" :on-create-click="store.getters.canCreateProjects ? openModal : null"/>
	<actionable-data-table
		ref="dataTable"
		:data-source="dataSource"
		:edit-at-row="editAtRow"
		:detail-at-row="detailAtRow"
		:delete-at-row="deleteAtRow"
		:header-mapping="headerMapping"
		:rpp="10"
	/>

	<create-project-modal ref="createProjectModal" />
	<edit-project-modal ref="editProjectModal" />
	<confirmation-modal
		ref="deleteProjectModal"
		value="delete"
		:action="deleteProject"
		action-title="Delete Project"
	/>
</template>

<script>

import request from "../request/index"
import PageHeader from "../components/PageHeader";
import ActionableDataTable from "../components/ActionableDataTable.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";
import EditProjectModal from "../components/EditProjectModal.vue";
import ConfirmationModal from "../components/ConfirmationModal.vue";
import {useStore} from "vuex";
import CreateProjectModal from "../components/CreateProjectModal.vue";

export default {
	name: "Projects",
	components: {
		CreateProjectModal,
		ConfirmationModal,
		EditProjectModal,
		Breadcrumbs,
		ActionableDataTable,
		PageHeader
	},
	setup() {
		const store = useStore()

		return {
			store
		}
	},
	data() {
		return {
			dataSource: (page, rpp) => new Promise((resolve, reject) => {
				request.get(process.env.VUE_APP_BASE_URL + '/projects?page=' + page + '&rpp=' + rpp)
					.then(response => resolve(response))
					.catch(error => reject(error))
			}),
			headerMapping: {
				name: 'Name',
				organization: 'Organization',
				last_deployed: 'Last Deployed',
				servers: {
					label: 'Servers',
					array: true
				}
			},
			loading: false,
			showModal: false
		}
	},
	methods: {
		editAtRow(id) {
			this.$refs.editProjectModal.id = id
			this.$refs.editProjectModal.showModal = true
		},
		detailAtRow(id) {
			this.$router.push({
				name: 'ProjectDetails',
				params: {
					id
				}
			})
		},
		deleteAtRow(item) {
			this.$refs.deleteProjectModal.resourceName = item.name
			this.$refs.deleteProjectModal.showModal = true
			this.$refs.deleteProjectModal.actionTitle = 'Delete Project'
			this.$refs.deleteProjectModal.action = (done) => {
				this.deleteProject(item.id, done)
			}
			this.$refs.deleteProjectModal.value = item.name
		},
		deleteProject(id, done) {
			this.$refs.deleteProjectModal.loading = true

			request.delete(process.env.VUE_APP_BASE_URL + `/projects/${id}`)
				.then(data => {
					this.$refs.deleteProjectModal.loading = false

					if (done) {
						done()
						this.$refs.deleteProjectModal.fieldValue = null
						this.$refs.dataTable.reload()
					}
				})
				.catch(error => {
					this.$refs.deleteProjectModal.loading = false
					console.log(error)
				})
		},
		openModal() {
			this.$refs.createProjectModal.showModal = true
		}
	}
}
</script>

<style>

</style>