<script setup>
import {onBeforeMount, ref} from "vue"

const props = defineProps({
	dataSource: {
		type: Function,
		required: true
	},
	editAtRow: {
		type: Function,
		required: false
	},
	detailAtRow: {
		type: Function,
		required: false
	},
	deleteAtRow: {
		type: Function,
		required: false
	},
	headerMapping: {
		type: Object,
		required: true
	},
	rpp: {
		type: Number,
		required: false,
		default: 10
	},
	paginate: {
		type: Boolean,
		required: false,
		default: true
	},
	actionButtonName: {
		type: String,
		required: false
	},
	actionButtonDisabled: {
		type: Boolean,
		required: false,
		default: false
	},
	actionButtonDisabledText: {
		type: String,
		required: false,
		default: ''
	}
})

const emit = defineEmits(['onActionClick'])

const data = ref([])
const links = ref([])
const page = ref(1)
const total = ref(0)
const loading = ref(true)

const calculateValue = (key, item) => {
	let value = key.split('.').reduce((o,i) => o[i], item)

	if (!value) {
		return 'N/A'
	}

	return value
}

const pageChanged = (changedPage) => {
	loading.value = true
	page.value = changedPage
	props.dataSource(page.value, props.rpp ?? 10)
		.then(response => {
			loading.value = false

			data.value = response.data

			if (props.paginate) {
				total.value = response.meta.pagination.total
				links.value = response.meta.pagination.links
			}
		})
		.catch(error => {
			loading.value = false
			console.log(error)
		})
}

const onActionClick = () => {
	emit('onActionClick')
}

onBeforeMount(() => {
	pageChanged(1)
})

const reload = () => {
  pageChanged(1)
}

defineExpose({
  reload
})

</script>

<template>
	<var-skeleton :rows="10" :loading="loading">
		<var-table>
			<thead>
			<tr class="fw-bolder text-muted">
				<th v-for="field in props.headerMapping">
					<template v-if="field instanceof Object">
						{{ field.label }}
					</template>
					<template v-else>
						{{ field }}
					</template>
				</th>
				<th>
					Actions
				</th>
			</tr>
			</thead>
			<tbody>
			<tr v-for="(item, index) in data">
				<td v-for="(field, key) in props.headerMapping">
					<template v-if="field instanceof Object">
						<var-chip v-if="field.chip" :type="field.type(calculateValue(key, item))">
							{{ calculateValue(key, item) }}
						</var-chip>
						<var-space v-else-if="field.array">
							<var-chip
								v-for="(element, i) in calculateValue(key, item)"
								:key="i"
								type="info"
								size="small"
							>
								{{ element }}
							</var-chip>
						</var-space>
					</template>
					<template v-else>
						{{ calculateValue(key, item) }}
					</template>
				</td>
				<td>
					<var-space>
						<var-button v-if="detailAtRow" @click="detailAtRow(item.id)" type="info" size="small">
							Details
						</var-button>
						<var-button v-if="editAtRow" @click="editAtRow(item.id)" type="primary" size="small">
							Edit
						</var-button>
						<var-button v-if="deleteAtRow" @click="deleteAtRow(item)" type="danger" size="small">
							Delete
						</var-button>
					</var-space>
				</td>
			</tr>
			</tbody>

			<template #footer>
				<div class="footer">
					<div v-if="actionButtonName" class="container">
						<div class="row">
							<div class="col-6">
								<var-button
									type="primary"
									@click="onActionClick"
									:disabled="actionButtonDisabled"
									:title="actionButtonDisabledText">
									{{ actionButtonName }}
								</var-button>
							</div>
							<div class="col-6">
								<span class="float-end">
								<var-pagination
									v-if="props.paginate"
									:simple="false"
									:current="page"
									:total="total"
									@change="pageChanged"
									:show-size-changer="false"
									:size="rpp"
								/>
							</span>
							</div>
						</div>
					</div>
					<var-pagination
						v-else-if="props.paginate"
						:simple="false"
						:current="page"
						:total="total"
						@change="pageChanged"
						:show-size-changer="false"
					/>
				</div>
			</template>
		</var-table>
	</var-skeleton>
</template>

<style scoped>

th:nth-child(n+1) {
	text-align: center !important;
}

th:nth-child(1) {
	text-align: left !important;
}

th:last-child {
	text-align: right !important;
}

td:nth-child(n+1) {
	text-align: center !important;
}

td:nth-child(1) {
	text-align: left !important;
}

td:last-child {
	text-align: right !important;
}

.footer {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	height: 60px;
	padding: 0 16px;
}

.var-space {
	display: inline-flex !important;
}

</style>