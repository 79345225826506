<script setup>
import request from "../request"
import {reactive, ref, watch} from "vue"
import {useRouter} from "vue-router"

const router = useRouter()

const loading = ref(false)
const loadingAccounts = ref(true)
const loadingRepositories = ref(false)

const showModal = ref(false)

const accounts = ref(null)
const repositories = ref(null)

const form = ref(null)
const formData = reactive({
	name: null,
	account_id: null,
	repository_name: null
})

const getAccounts = () => {
	loadingAccounts.value = true
	request.get(process.env.VUE_APP_BASE_URL + '/vcs/get_accounts')
		.then(data => {
			loadingAccounts.value = false
			accounts.value = data
		})
		.catch(error => {
			loadingAccounts.value = false
			console.log(error)
		})
}

const getRepositories = () => {
	if (!repositories.value) {
		loadingRepositories.value = true
		request.get(process.env.VUE_APP_BASE_URL + `/accounts/${formData.account_id}/get_repositories`)
			.then(data => {
				loadingRepositories.value = false
				repositories.value = data
			})
			.catch(error => {
				loadingRepositories.value = false
				console.log(error)
			})
	}
}

const onBeforeClose = (action, done) => {
	if (action === 'confirm') {
		form.value.validate().then(validated => {
			if (!validated) {
				return
			}

			loading.value = true

			request.post(process.env.VUE_APP_BASE_URL + '/projects', formData)
				.then(data => {
					loading.value = false
					done()
					router.push({
						name: 'ProjectDetails',
						params: {
							id: data.id,
							tab: 'servers'
						}
					})
				})
				.catch(error => {
					loading.value = false
					console.log(error)
				})
		})
	} else {
		done()
	}
}

watch(showModal, async (newVal, oldVal) => {
	if (newVal === true && !accounts.value) {
		getAccounts()
	}
})

defineExpose({
	showModal
})

</script>

<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px'}">
		<var-dialog
			v-model:show="showModal"
			confirm-button-text="Create"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose"
		>
			<template #title>
				Create Project
			</template>

			<var-skeleton :loading="loadingAccounts">
				<var-form ref="form">
					<var-input
						placeholder="Please input name"
						:rules="[
							v => !!v || 'The project name cannot be empty',
							v => !!v && v.length <= 255 || 'The project name is too long'
						]"
						v-model="formData.name"
						class="mb-5"/>

					<var-select
						v-if="accounts"
						placeholder="Please select an account"
						v-model="formData.account_id"
						@change="getRepositories"
						:rules="[
							v => !!v || 'Yo have to select an account'
						]"
					>
						<var-option v-for="account in accounts" :label="account.login" :value="account.id" />
					</var-select>

					<var-loading v-if="formData.account_id" :loading="loadingRepositories">
						<var-select
							placeholder="Please select a repository"
							v-model="formData.repository_name"
							:rules="[
								v => !!v || 'Yo have to select a repository'
							]"
						>
							<var-option
								v-for="repository in repositories"
								:label="repository.full_name"
								:value="repository.full_name"
							/>
						</var-select>
					</var-loading>
				</var-form>
			</var-skeleton>
		</var-dialog>
	</var-style-provider>
</template>

<style scoped>

</style>