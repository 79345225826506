<script setup>
import {reactive, ref, watch} from "vue"
import request from "../request"
import {useRouter} from "vue-router"

const formData = reactive({
	name: ''
})

const form = ref(null)

const id = ref(null)
const loading = ref(false)
const showModal = ref(false)

defineExpose({
	id,
	showModal,
	formData
})

const router = useRouter()

const getProject = () => {
	loading.value = true
	request.get(process.env.VUE_APP_BASE_URL + `/projects/${id.value}`)
		.then(data => {
			loading.value = false
			formData.name = data.data.name
		})
		.catch(error => {
			loading.value = false
			console.log(error)
		})
}

watch(showModal, async (newVal, oldVal) => {
	if (newVal === true) {
		getProject()
	}
})

const onBeforeClose = (action, done) => {
	if (action === 'confirm') {
		form.value.validate().then(validated => {
			if (!validated) {
				return
			}

			loading.value = true

			request.put(process.env.VUE_APP_BASE_URL + `/projects/${id.value}`, {
				name: formData.name
			})
				.then(data => {
					loading.value = false

					done()
					router.push({
						name: 'ProjectDetails',
						params: {
							id: id.value
						}
					})
				})
				.catch(error => {
					loading.value = false
					console.log(error)
				})
		})
	} else {
		done()
	}
}

</script>

<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px'}">
		<var-dialog
			v-model:show="showModal"
			confirm-button-text="Save"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Update Project
			</template>

			<var-form ref="form">
				<var-input
					placeholder="Please input name"
					:rules="[v => !!v || 'The project name cannot be empty']"
					v-model="formData.name"
					class="mb-5" />
			</var-form>
		</var-dialog>
	</var-style-provider>
</template>

<style scoped>

</style>